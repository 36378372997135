.Block {
    $block: &;

    &--blockModifier {
        #{$block}-element {
            color: #b4da55;
        }
    }

    &-element {
        &--elementModifier {
            color: #b4da55;
        }
    }

    &.is-stateType {
        #{$block}-element {
            color: #b4da55;
        }
    }

    &.has-stateType {
        #{$block}-element {
            color: #b4da55;
        }
    }
}
