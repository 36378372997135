.PointList {
    $block: &;
    margin-top: ms($spacer-kilo-ms);
    margin-bottom: ms($spacer-kilo-ms);

    &--ordered,
    &--unordered {
        padding-left: rem(18);

        #{$block}-item {
            padding-left: ms($spacer-kilo-ms);
        }
    }

    &--unordered {
        list-style: circle;
    }

    &--ordered {
        list-style: decimal;
    }

    &-item + &-item {
        margin-top: ms($spacer-kilo-ms);
    }
}
