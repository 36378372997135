%u-inline-link-style {
    @extend %u-text-style-h;
    color: $color-text-brand;

    &:visited {
        color: $color-text-visited;
    }
}

.InlineLink {
    $block: &;
    @extend %u-inline-link-style;

    &--right {
        float: right;
    }

    &--noborder {
        text-decoration: none;
    }

    &--arrow {
        display: block;
        background-image:  url('/power_of_play/static/images/arrow-right.png');
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 1rem 1.75rem;
        line-height: 1.75rem;
    }

    &--twitter {
        display: block;
        background-image:  url('/power_of_play/static/images/logos/twitter.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 1.75rem 1.75rem!important;
        line-height: 1.75rem!important;
        text-decoration: none!important;
        padding-left: 2.25rem;
    }

    &--facebook {
        display: block;
        background-image:  url('/power_of_play/static/images/logos/facebook.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 1.75rem 1.75rem!important;
        line-height: 1.75rem!important;
        text-decoration: none!important;
        padding-left: 2.25rem;
    }

    &--social {
        text-decoration: none;
    }
}
