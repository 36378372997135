.Tile {
    $block: &;
    display: block;

    &-header {
        @include text(2, e);
        margin-bottom: ms($spacer-kilo-ms);
        color: $color-text-brand;

        strong {
            font-weight: $font-weight-bold;
        }
    }

    &-description {
        margin: ms($spacer-kilo-ms) 0;
    }

    &-callToAction {
        margin: ms($spacer-mega-ms) 0;
    }

    &-graphic {
        display: block;
        max-width: 100%;
        height: auto;
        margin: ms($spacer-kilo-ms) auto;
    }
}
