.SessionListing {
    $block: &;
    display: block;

    &-list {
        &Item {
            margin-bottom: ms($spacer-mega-ms);
        }
    }

    &-text {
        display: block;
    }

    &-icon {
        display: none;
    }
}
