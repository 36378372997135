.PageTitle {
    $block: &;
    display: block;
    color: $color-text-brand;

    &-text {
        @include text(7, b);
        display: block;

        &--strong {
            @include text(7, c);
        }
    }

    &-description {
        @include text(0, g);
        margin-bottom: ms($spacer-mega-ms);
        color: $color-text-disabled;
    }

    &-divider {
        padding: px($spacer-kilo) 0 px($spacer-mega);
    }
}
