.ButtonGroup {
    $block: &;

    &-item + &-item {
        @if $enhanced == true {
            margin-top: ms($spacer-kilo-ms);
        } @else {
            margin-top: px($spacer-kilo);
        }
    }

    &--promo {
        @if $enhanced == true {
            .Button--primary,
            .Button--secondary {
                width: 50%;
                border: 0;
                @include text(0, g);
                font-weight: $font-weight-medium;
            }
        } @else {
            margin-top: px($spacer-kilo);
        }
    }

    &--noborder {
        .Button--primary,
        .Button--secondary {
            border: 0;
        }
    }

    &--no-margin-bottom {
        margin-bottom: 0!important;
    }
}
