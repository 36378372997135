.Logo {
    $block: &;
    display: block;
    width: ms(12);

    @if $enhanced == true {
        position: relative;
        padding-top: 47%;
        overflow: hidden;
    }

    &--small {
        width: ms(10);
    }

    &--large {
        width: ms(14);
    }

    &--huge {
        width: ms(16);
    }

    &--wide {
        width: 100%;
        padding: ms($spacer-kilo-ms) ms($spacer-mega-ms);

        svg {
            width: 100%;
        }
    }

    &-graphic {
        display: block;
        width: 100%;
        height: auto;

        @if $enhanced == true {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
        }
    }
}
