.Divider {
    $block: &;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    border-width: px($border-width-hairline) 0 0 0;
    border-style: solid;
    border-color: $color-border-brand;

    &--dense {
        border-top-width: px($border-width-dense);
    }

    &--inline {
        margin-top: ms($spacer-mega-ms);
        margin-bottom: ms($spacer-mega-ms);
    }

    &--cramped {
        margin-top: ms($spacer-kilo-ms);
        margin-bottom: ms($spacer-mega-ms);
    }

    &--semi {
        opacity: 0.8;
        border-color: $color-border-disabled;
    }

    &--faded {
        opacity: 0.3;
        border-color: $color-border-disabled;
    }

    &--hairline {
        border-width: 1px 0 0 0;
        opacity: 0.8;
        border-color: $color-border-brand;
    }

    @each $key, $value in $brand-colors-map {
        &--color#{capitalize($key)} {
            border-color: $value;
        }
    }
}
