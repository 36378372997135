.Answer {
    $block: &;
    display: block;

    &-title {
        display: block;
    }

    &-entered {
        display: block;
    }

    &-feedback {
        display: block;
    }
}
