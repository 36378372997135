.Table {
    width: 100%;

    th,
    td {
        padding: ms(-5);
        text-align: left;
    }

    th {
        font-weight: bold;
    }

    td {

    }
}
