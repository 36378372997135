.Registration {
    &-form {
        .Field-label {
            &::after {
                display: inline-block;
                margin-left: ms(-4);
                color: $color-text-required;
                content: "*";
            }
        }

    }
}
