* {
    box-sizing: border-box;
}

html {
    background-color: $color-background-brand;
    font-family: $font-family-base;
    font-size: px($font-size-kilo);
}

body {
    @extend %u-text-style-g;
    max-width: em(768);
    margin: 0 auto;
    background-color: $color-background;
    color: $color-text;
}

// The Symbols element contains the entire inlined SVG spritemap icon system for use elsewhere.
.Symbols {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    clip: rect(0 0 0 0);
    border: 0;
    white-space: nowrap;
    overflow: hidden;
}

svg {
    font-family: $font-family-brand-round;
    font-weight: $font-weight-medium;
}

.no-margin-top {
    margin-top: 0!important;
}

.no-margin-bottom {
    margin-bottom: 0!important;
}

.no-padding-top {
    padding-top: 0!important;
}