
$brand-colors-map: (
  'prussian': (#005487),
  'viking': (#41c0c6),
  'alizarin': (#ec1d24),
  'gold': (#ffd500),
  'mulberry': (#c45398),
  'mantis': (#83c341),
  'carrot': (#eb7323),
);
