.NavBar {
    $block: &;

    &-navItem {
        display: inline-block;
        zoom: 1;
    }

    &-navLink {
        @include text(1, e);
        display: block;
        padding: 5px;
        color: $color-text-link;
        text-decoration: none;

        &.is-active,
        &:active {
            background-color: $color-background-interactive;

            &:hover,
            &:focus {
                background-color: $color-background-interactive;
            }
        }

        &:hover,
        &:focus {
            background-color: tint($color-background-interactive, 50%);
        }

        &.on-blue {
            color: $color-text-white;
            text-decoration: underline;

            &:hover,
            &:focus {
                color: $color-text-brand;
                text-decoration: none;
            }
        }
    }
}
