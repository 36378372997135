
$border-width-hairline: 2;
$color-border-interactive: #ffd500;
$color-border-success: #83c341;
$color-border-brand: #005487;
$color-border-disabled: #e2e2e2;
$border-radius-rounded: 5;
$border-width-dense: 6;
$border-radius-round: 100;
$color-border-danger: #ec1d24;
