.SiteHead {
    $block: &;
    padding: px($spacer-kilo) 0;
    background-color: $color-background-brand;
    text-align: center;

    &--bordered {
        border-bottom-width: px($border-width-hairline);
        border-style: solid;
        border-color: $color-border-brand;
    }

    &-actions {
        margin-top: px($spacer-kilo);
    }

    &-brand {
        .Logo {
            margin: 0 auto;
        }
    }
}
