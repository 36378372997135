.Form {
    $block: &;

    &-title {
        @include text(2, e);
        display: inline-block;
        width: 100%;
        margin-bottom: px($spacer-kilo);
        color: $color-text-brand;
    }

    &-text {
        @include text(0, g);
        margin-bottom: ms($spacer-kilo-ms);

        &--colorBrand {
            color: $color-text-brand;
        }

        &--styleBold {
            font-weight: $font-weight-bold;
        }
    }

    &-group + &-group {
        margin-top: px($spacer-mega);
        padding-top: px($spacer-mega);
    }

    &-field + &-field {
        margin-top: px($spacer-mega);
    }

    &-action {
        margin-top: px($spacer-mega);
    }

    &-message {
        margin: px($spacer-giga) 0;

        &Title {
            @include text(2, f);
            margin-bottom: px($spacer-kilo);
            color: $color-text-brand;
        }

        &Text {
            @include text(0, g);
        }
    }

    .Hidden {
        display: none;
    }
}
