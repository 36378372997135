.Pagination {
    $block: &;
    display: block;
    text-align: center;

    &-increment,
    &-jump {
        display: inline-block;
        zoom: 1;
    }

    &-increment {
        &--prev {
            float: left;
        }

        &--next {
            float: right;
        }

        &Link {
            @include text(2, e);
            color: $color-background-alt;

            &--active {
                color: $color-text-brand;
            }
        }
    }

    &-jump {
        & + & {
            margin-left: ms(-5);
        }

        &Link {
            &--active {
                color: $color-text-brand;
            }
        }
    }
}
