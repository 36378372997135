
$font-size-mega: 19.556;
$font-size-exa: 44;
$font-family-brand-round-capheight: 0.75;
$font-family-base-round-capheight: 0.75;
$font-weight-regular: 400;
$font-size-giga: 24;
$font-family-brand-round: GothamRounded-Light, ArialRounded, helvetica;
$font-weight-medium: 500;
$font-size-deca: 10;
$font-size-tera: 29.333;
$font-size-kilo: 16;
$font-size-peta: 40;
$font-size-hecto: 12;
$font-size-deci: 8;
$font-family-brand-capheight: 0.8;
$font-family-brand: GothamRounded-Bold, ArialRounded, helvetica;
$font-family-base: GothamRounded-Light, ArialRounded, helvetica;
$font-weight-bold: 700;
$font-weight-light: 300;
