.u {
    &-spacer {
        &-mt {
            &-kilo {
                margin-top: ms($spacer-kilo-ms) !important;
            }

            &-mega {
                margin-top: ms($spacer-mega-ms) !important;
            }

            &-giga {
                margin-top: ms($spacer-giga-ms) !important;
            }

            &-tera {
                margin-top: ms($spacer-tera-ms) !important;
            }
        }

        &-mb {
            &-kilo {
                margin-bottom: ms($spacer-kilo-ms) !important;
            }

            &-mega {
                margin-bottom: ms($spacer-mega-ms) !important;
            }

            &-giga {
                margin-bottom: ms($spacer-giga-ms) !important;
            }

            &-tera {
                margin-bottom: ms($spacer-tera-ms) !important;
            }
        }

        &-pt {
            &-kilo {
                padding-top: ms($spacer-kilo-ms) !important;
            }

            &-mega {
                padding-top: ms($spacer-mega-ms) !important;
            }

            &-giga {
                padding-top: ms($spacer-giga-ms) !important;
            }

            &-tera {
                padding-top: ms($spacer-tera-ms) !important;
            }
        }

        &-pb {
            &-kilo {
                padding-bottom: ms($spacer-kilo-ms) !important;
            }

            &-mega {
                padding-bottom: ms($spacer-mega-ms) !important;
            }

            &-giga {
                padding-bottom: ms($spacer-giga-ms) !important;
            }

            &-tera {
                padding-bottom: ms($spacer-tera-ms) !important;
            }
        }
    }

    &-no-margin {
        margin: 0;
    }
}
