.Paper {
    &-header {
        @include text(7, c);
        margin-bottom: ms($spacer-mega-ms);
        padding-bottom: ms($spacer-mega-ms);
        border-bottom-width: px($border-width-dense);
        border-style: solid;
        border-color: $color-border-brand;
        color: $color-text-brand;
    }

    &-footer {
        margin-top: ms($spacer-tera-ms);
    }

    &-multiMedia {
        margin: ms($spacer-giga-ms) 0;
    }

    &-content {
        margin: ms($spacer-mega-ms) 0;
        color: $color-text;
        line-height: 1.5;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: ms($spacer-tera-ms);
            margin-bottom: ms($spacer-kilo-ms);
            color: $color-text-brand;
        }

        h1 {
            @include text(5, d);
        }

        h2 {
            @include text(2, e);
        }

        h3 {
            @include text(1, c);
        }

        h4 {
            @include text(1, c);
            text-transform: uppercase;
        }

        h5 {
            @include text(1, c);
            font-style: italic;
        }

        h6 {
            @include text(0, e);
        }

        i,
        em {
            font-style: italic;
        }

        b,
        strong {
            font-weight: 700;
        }

        a {
            @extend %u-inline-link-style;
        }

        p {
            margin-bottom: ms($spacer-kilo-ms);

            code {
                display: inline-block;
                margin: 0;
                padding: 0 em(5);
                background-color: $color-background-alt;
                font-family: monospace;
            }
        }

        code {
            display: block;
            margin: ms($spacer-mega-ms);
            padding: ms($spacer-kilo-ms);
            background-color: $color-background-alt;
            font-family: monospace;
        }

        mark {
            padding: 0 em(5);
            background-color: $color-text-brand;
            color: $color-text-white;
        }

        ul,
        ol {
            margin-top: ms($spacer-kilo-ms);
            margin-bottom: ms($spacer-kilo-ms);
            padding-left: em(18);

            li {
                padding-left: ms($spacer-kilo-ms);
            }
        }

        ul {
            list-style: disc;
        }

        ol {
            list-style: decimal;
        }

        blockquote {
            @include text(3, d);
            margin: ms($spacer-tera-ms);
            margin-right: 0;
            color: $color-text-brand;
            font-style: italic;
            line-height: 1.618;
            text-align: right;
        }

        dl {
            margin-top: ms($spacer-tera-ms);
            margin-bottom: ms($spacer-tera-ms);
            padding-left: ms($spacer-mega-ms);
        }

        dt {
            @include text(0, e);
            margin-top: ms($spacer-kilo-ms);
            color: $color-text-brand;
        }

        dd {
            margin-bottom: ms($spacer-kilo-ms);
            padding-left: ms($spacer-mega-ms);
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto 1rem auto;
        }

        .Button {
            text-decoration: none!important;
            color: $color-text-brand!important;
        }

        .Pagination ul,
        .Pagination li {
            padding-left: 0;
        }

        table {
            width: 100%;

            th,
            td {
                padding: ms(-5);
                text-align: left;
            }

            th {
                font-weight: bold;
            }

            td {

            }
        }
    }
}

.Session-content {
    h2:first-child {
        margin-top: ms($spacer-kilo-ms);
    }
}

.Flatpage-content {
    h1 {
        margin-top: ms($spacer-mega-ms);
    }
    a {
        color: $color-text-brand!important;
        text-decoration: none!important;
    }
}
