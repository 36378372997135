.ChoiceBox {
    $block: &;

    &-item {
        display: block;

        & + & {
            margin-top: ms($spacer-kilo-ms);
        }
    }

    &-input {
        display: inline-block;
        margin-right: ms(0);
    }
}


.Field-item li {
    margin-bottom: ms($spacer-kilo-ms);
}

.RadioSelect {
    vertical-align: middle;
    &-label {
        color: $color-text-brand;
    }
}
