.Footer {
    $block: &;
    padding: px($spacer-mega);
    background-color: $color-background-alt;
    text-align: center;

    &-divider {
        margin: px($spacer-kilo) 0;
    }
}
