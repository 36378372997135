
$color-background-warning: #eb7323;
$color-background-black: #000000;
$color-background: #ffffff;
$color-background-interactive: #ffd500;
$color-background-success: #83c341;
$color-background-disabled: #e2e2e2;
$color-background-brand: #005487;
$color-background-alt: #f1f1f1;
$color-background-danger: #ec1d24;
$color-background-gray: #9b9b9b;
