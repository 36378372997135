%u-text-style-a {
    font-weight: $font-weight-regular;
    line-height: 1.1;

    @if $enhanced == true {
        .wf-active & {
            @extend %u-font-family-brand-round;
            letter-spacing: -(kern(1));
        }
    }
}

%u-text-style-b {
    font-weight: $font-weight-light;
    line-height: 1.1;

    @if $enhanced == true {
        .wf-active & {
            @extend %u-font-family-brand-round;
            letter-spacing: -(kern(1));
        }
    }
}

%u-text-style-c {
    font-weight: $font-weight-medium;
    line-height: 1.1;

    @if $enhanced == true {
        .wf-active & {
            @extend %u-font-family-brand-round;
            letter-spacing: -(kern(1));
        }
    }
}

%u-text-style-d {
    font-weight: $font-weight-light;
    line-height: 1;

    @if $enhanced == true {
        .wf-active & {
            @extend %u-font-family-brand-round;
            letter-spacing: -(kern(1));
        }
    }
}

%u-text-style-e {
    font-weight: $font-weight-medium;
    line-height: 1;

    @if $enhanced == true {
        .wf-active & {
            @extend %u-font-family-brand-round;
            letter-spacing: -(kern(1));
        }
    }
}

%u-text-style-f {
    font-weight: $font-weight-light;
    line-height: 1;

    @if $enhanced == true {
        .wf-active & {
            @extend %u-font-family-brand-round;
            letter-spacing: -(kern(1));
        }
    }
}

%u-text-style-g {
    font-weight: $font-weight-light;
    line-height: 1.25;

    @if $enhanced == true {
        .wf-active & {
            @extend %u-font-family-brand;
            letter-spacing: -(kern(.5));
        }
    }
}

%u-text-style-h {
    font-weight: $font-weight-medium;
    line-height: 1.25;
    text-decoration: underline;

    @if $enhanced == true {
        .wf-active & {
            @extend %u-font-family-brand;
            letter-spacing: -(kern(.5));
        }
    }
}
