
$color-text: #747474;
$color-text-interactive: #ffd500;
$color-text-success: #83c341;
$color-text-link: #005487;
$color-text-brand: #005487;
$color-text-disabled: #747474;
$color-text-white: #ffffff;
$color-text-visited: #c45398;
$color-text-required: #c45398;
$color-text-danger: #ec1d24;
