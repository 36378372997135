@mixin placeholder {
  &::placeholder {
    @content;
  }
}

%Input {
    $block: &;

    @include text(2, e);
    display: block;
    width: 100%;
    padding: ms(-2) 0;
    border-width: px($border-width-hairline);
    border-style: solid;
    border-color: $color-border-interactive;
    outline: none;
    background-color: $color-background;
    color: $color-text-brand;
    text-indent: ms(3);

    @include placeholder {
        color: grayscale($color-text-brand);
    }

    &:focus {
        background-color: $color-background-interactive;
    }

    &--invalid {
        border-color: $color-background-danger;

        &:focus {
            background-color: $color-border-danger;
            color: $color-text-white;
        }
    }

    &--valid {
        border-color: $color-border-success;

        &:focus {
            background-color: $color-background-success;
            color: $color-text-white;
        }
    }

    &:disabled,
    &:read-only {
        border-color: $color-border-disabled;
        background-color: $color-background-disabled;
        color: grayscale($color-text-brand);
        cursor: not-allowed;
    }
}

.Input {
    @extend %Input;
}

.Text,
.Number,
.Email,
.URL,
.Password,
.Date,
.DateTime,
.Time {
    &Input {
        &-input {
            @extend %Input;
        }
    }
}

.Textarea {
    @extend %Input;
}

.RadioSelect,
.CheckboxInput {
    &-input {
        display: inline-block;
        margin-right: px($spacer-kilo);
    }
}
