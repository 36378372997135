.ImageGrid {
    $block: &;
    display: block;
    text-align: center;

    &-item {
        display: inline-block;
        zoom: 1;
    }

    &-image {
        @include responsive-image;
    }
}
