.Signpost {
    $block: &;
    display: block;

    &-icon {
        display: none;
    }

    &-text {
        display: block;
    }
}
