.Message {
    $block: &;
    display: block;
    margin-top: ms($spacer-mega-ms);
    margin-bottom: ms($spacer-mega-ms);

    &-title {
        @include text(5, d);
        color: $color-text-brand;

        @if $enhanced == true {
            margin-bottom: ms($spacer-kilo-ms);
        } @else {
            margin-bottom: px($spacer-kilo);
        }
    }

    &-subtitle {
        @include text(2, e);
    }

    &-text {
        @include text(0, g);
        color: $color-text;
        margin-top: ms($spacer-kilo-ms);
        margin-bottom: ms($spacer-kilo-ms);

        &:last-child {
            margin-bottom: 0;
        }

        &--colorAlizarin {
            color: $alizarin;
        }
    }

    &-rounded {
        border-radius: 10px;
        padding: ms($spacer-kilo-ms);

        &--blue-background {
            background-color: $color-text-brand;

            .Message-text {
                color: $color-text-white;

                &--colorAlizarin {
                    color: $alizarin;
                }
            }
        }
    }
}
