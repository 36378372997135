.ArrowBox {
    $block: &;

    &-body {
        padding: px($spacer-kilo) 0;
        border-width: px($border-width-dense) 0 0 0;
        border-style: solid;
        border-color: $color-border-interactive;

        strong {
            font-weight: $font-weight-bold;
        }

        .HeroTitle-description {
            display: block;

            br {
                display: none;
            }
        }

        .HeroTitle-sub {
            display: block;
            margin-bottom: px($spacer-kilo);
            color: $color-text-brand;
        }
    }

    &-promo {
        #{$block}-body {
            border-top: 0;
            padding-top: 0;
        }
        .HeroTitle-text {
            color: $color-text-brand;
        }
        .HeroTitle-description {
            margin-top: px($spacer-kilo);
        }
    }

    &-bg-alt {
        .Divider, .Logo--wide {
            display: none;
        }
    }

     &-bg-alternating{
        .Divider, .Logo--wide {
            display: none;
        }
    }

    &-bg-black {
        .HeroTitle-sub {
            margin-bottom: 0;
        }
        .HeroTitle-sub,
        .HeroTitle-text{
            color: $color-text-brand!important;
        }
        .HeroTitle-description {
            color: $color-text!important;
        }
    }

    .Testimonials {
        border: 1px solid $color-border-disabled;
        padding: px($spacer-kilo);
        margin-bottom: px($spacer-kilo);

        .Avatar-image {
            display: none;
        }
    }

    .IconGroup {
        margin: px($spacer-kilo) 0;

        .Icon {
            width: ms(6);
            margin-right: px($spacer-kilo);
            display: inline-block;
        }
    }

    .Usp {
        border-top: 1px solid $color-border-disabled;
        padding-top: px($spacer-kilo);
        margin-top: px($spacer-kilo);

        &-title {
            color: $color-text-brand;
            margin: px($spacer-kilo) 0;
        }
    }

    .ButtonGroup {
        margin-top: px($spacer-kilo);
    }
}
