.Video {
    $block: &;

    &-image {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }

    &-text {
        &--showLoaded {
            display: none;

            .is-loaded & {
                display: block;
            }
        }

        &--hideLoaded {
            .is-loaded & {
                display: none;
            }
        }
    }
}

.Smart {
    display: none;
}

.Feature {
    display: block;
}
