.Field {
    $block: &;
    display: block;

    & + & {
        //margin-top: ms(4);
    }

    &-label {
        @include text(2, f);
        display: block;
        color: $color-text-brand;

        a {
            color: $color-text-brand;
        }

        #{$block}--required & {
            &::after {
                display: inline-block;
                margin-left: ms(-4);
                color: $color-text-required;
                content: "*";
            }
        }

        #{$block}--invalid & {
            color: $color-text-danger;
        }

        #{$block}--valid & {
            color: $color-text-success;
        }
    }

    &-message,
    ul.errorlist {
        @include text(0, g);
        display: block;
        margin-top: ms(-4);

        &--hintText {
            color: $color-text-brand;
        }

        &--errorText,
        > li {
            color: $color-text-danger;
        }
    }

    &-message {
        a {
            color: tint($color-text-brand, 50%);
        }
    }

    &-item {
        display: block;
    }

    &-divider-text {
        @include text(4, e);
        position: relative;
        color: $color-text-brand;
        text-align: center;
        text-transform: uppercase;

        &::before,
        &::after {
            position: absolute;
            top: 50%;
            width: 40%;
            height: rem(5);
            background-color: $color-background-gray;
            transform: translateY(-50%);
            opacity: 0.5;
            content: "";
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
        }
    }
}
