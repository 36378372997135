.Attendance {
    $block: &;
    display: block;
    padding-bottom: ms($spacer-kilo-ms);

    &-title,
    &-label {
        margin-top: ms($spacer-kilo-ms);
        margin-bottom: ms($spacer-kilo-ms);
    }
}
